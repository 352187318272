
















import Vue from 'vue'
export default Vue.extend({
  name: 'BaseCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    checkboxState: {
      type: Boolean
    },
    labelClass: {
      type: String
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeState: {
      get () {
        return this.checkboxState
      },
      set (v) {
        this.$emit('change-state', v)
      }
    }
  },
  components: {},
  methods: {}
})
