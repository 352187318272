






































import Vue from 'vue'
import ImageList from './ImageList.vue'
import ImageItem from './ImageItem.vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'Media',
  components: {
    ImageList,
    ImageItem
  },
  props: ['selectedMedia'],
  data () {
    return {
      media_type_chosen: null,
      activeImg: 0,
      mediaType: 'images'
    }
  },
  methods: {
    url (ind) {
      return process.env.VUE_APP_BASE_URL +
        '/user_data/user_files/' +
        this.media[ind].attributes.user_id +
        '/images/' + this.media[ind].attributes.md5_hash +
        '.' + this.media[ind].attributes.format
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['media'])
  },
  created () {
    this.$emit('checkMedia')
  }
  // mounted() {
  //     if (!this.mediaLibrary[this.mediaType].length)
  // }
})
