




































import Vue from 'vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import TextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
export default Vue.extend({
  name: 'PrimaryText',
  components: { TextArea },
  data () {
    return {
      text: ''
    }
  },
  props: [],
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignServerData', 'adDesignData']),
    text_arr: {
      get () {
        return this.adDesignData.ad_text
      },
      set (v) {
        this.setStateFieldProp({ field: 'adDesignData', prop: 'ad_text', value: v })
      }
    }
  },
  watch: {
    text_arr () {
      this.text = ''
    },
    text () {
      this.changePrimaryTextState(this.text)
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    ...mapMutations('adsManager/adDesign', ['changePrimaryTextState']),
    addItem (arr, item, searchKey = null) {
      if (searchKey) {
        if (arr.filter(v => v[searchKey] === item[searchKey]).length === 0) {
          arr.push(item)
        } else {
          this.addNotification({ id: Date.now(), body: 'Duplicate value', type: 'danger' })
        }
      } else {
        if (arr.filter(v => v === item).length === 0) {
          arr.push(item)
        } else {
          this.addNotification({ id: Date.now(), body: 'Duplicate value', type: 'danger' })
        }
      }
    },
    removeItem (arr, item, searchKey = null) {
      let ind
      if (searchKey) {
        ind = arr.indexOf(arr.find(v => v[searchKey] === item[searchKey]))
        arr.splice(ind, 1)
      } else {
        ind = arr.indexOf(arr.find(v => v === item))
        arr.splice(ind, 1)
      }
    },
    changeText (text) {
      this.text = text
    }
  }
})
