

















import Modal from '../../components/Modal.vue'
export default {
  components: { Modal },
  props: {
    question: {
      type: String,
      default: '<h4>Are you sure?</h4>'
    },
    onSuccess: {
      type: [Function, Promise],
      required: false,
      default: () => ({})
    },
    onClose: {
      type: Function,
      default: () => ({})
    }
  },

  methods: {
    success: async function () {
      await this.onSuccess()
      this.$emit('delete-media')
      this.$modal.hide('confirmation-modal')
    }
  }
}
