



































import Vue from 'vue'
import DropHere from './DropHere.vue'
import ImageList from './ImageList.vue'
import ImageItem from './ImageItem.vue'
import { mapMutations, mapState } from 'vuex'

export default Vue.extend({
  components: {
    DropHere,
    ImageList,
    ImageItem
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      mediaType: 'images',
      // mediaForUploading: [],
      acceptTypes: {
        images: ['image/jpg', 'image/png', 'image/jpeg'],
        videos: [
          'video/mov',
          'video/mp4',
          'video/m4a',
          'video/3gp',
          'video/3g2',
          'video/mj2'
        ]
      },
      mediaModel: 'All media items',
      progress: 0
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignData'])
  },
  methods: {
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp']),
    saveData (media) {
      this.setStateFieldProp({ field: 'adDesignData', prop: 'mediaForUploading', value: media })
    },
    dropImages (fileList) {
      const media = [...this.adDesignData.mediaForUploading]
      Object.values(fileList).forEach((file: any) => {
        if (this.acceptTypes[this.mediaType].includes(file.type)) {
          const isExistingFile = media.find(img =>
            this.isEqualFiles(img.file, file)
          )
          if (!isExistingFile) {
            media.push({
              id: Math.floor(Math.random() * Date.now()),
              url: URL.createObjectURL(file),
              type: this.mediaType,
              file
            })
            URL.revokeObjectURL(file)
          }
        }
      })
      this.saveData(media)
    },
    removeImage (img) {
      this.saveData(
        this.adDesignData.mediaForUploading.filter(
          image => !this.isEqualFiles(image.file, img.file)
        )
      )
    },

    isEqualFiles (a, b) {
      return (
        a.name === b.name &&
        a.size === b.size &&
        a.type === b.type &&
        a.lastModified === b.lastModified
      )
    },

    sizeOf (bytes) {
      if (bytes === 0) return '0.00 B'

      const e = Math.floor(Math.log(bytes) / Math.log(1024))
      return (
        (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
      )
    },

    simulateLoading () {
      this.progress = this.progress + 5 > 100 ? 100 : this.progress + 5

      if (this.progress < 100) setTimeout(this.simulateLoading, 1000)
    }
  },
  updated () {
    this.$emit('change-media-arr')
  }
})
