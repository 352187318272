






















import Vue from 'vue'
export default Vue.extend({
  name: 'DropHere',
  components: {},

  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ''
    }
  },

  methods: {
    onDrop (e) {
      this.hideHighlight(e)
      const dt = e.dataTransfer
      this.$emit('change', dt.files)
    },
    showHighlight (e) {
      e.target.classList.add('highlight')
    },
    hideHighlight (e) {
      e.target.classList.remove('highlight')
    }
  }
})
