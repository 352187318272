










































import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import InstagramAccount from '@/components/ADSManager/NewCampaign/AdDesign/InstagramAccount.vue'
import VSelect from '../../../../components/BaseComponents/VSelect/VSelect.vue'
export default Vue.extend({
  name: 'FacebookPage',
  components: { VSelect, InstagramAccount },
  data () {
    return {
      selected: null,
      flag: false
    }
  },
  props: {
    serverData: {
      required: true
    },
    editable: {
      default: true
    }
  },
  watch: {
    pages (v) {
      this.selected = this.serverData.pages.find(v => String(v.attributes.fb_id) === String(this.serverData.data.fb_actor_id))
    },
    selected (v) {
      if (v) {
        this.get_instagram_accounts({ campaignId: this.$route.params.campaign_id, pageId: this.selected.attributes.fb_id })
        this.get_instant_forms({ campaignId: this.$route.params.campaign_id, pageId: this.selected.id, page: 1 })
        if (this.editable) {
          this.setStateFieldProp({ field: 'adDesignData', prop: 'page_id', value: v.attributes.fb_id })
        }
      }
    }
  },
  computed: {
    pages () {
      return this.serverData.pages
    }
  },
  mounted () {
    if (this.serverData.data.fb_actor_id) {
      this.selected = this.serverData.pages.find(v => String(v.attributes.fb_id) === String(this.serverData.data.fb_actor_id))
      if (this.editable) {
        this.setStateFieldProp({ field: 'adDesignData', prop: 'page_id', value: this.serverData.data.fb_actor_id })
      }
    } else {
      this.selected = this.serverData.pages[0]
      if (this.editable) {
        this.setStateFieldProp({ field: 'adDesignData', prop: 'page_id', value: this.serverData.pages[0].attributes.fb_id })
      }
    }
    setTimeout(() => { this.flag = true }, 1000)
  },
  methods: {
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp']),
    ...mapActions('adsManager/adDesign', ['get_instagram_accounts', 'get_instant_forms'])
  }
})
