import modal from './mixins_modal'
import Confirm from './Confirm.vue'

export function openModal (ctx, name, component, props) {
  ctx.$modal.show(
    component,
    props,
    {
      name,
      height: 'auto',
      adaptive: true,
      width: 900
    },
    {
      'before-close': modal.methods.remove_modal_open_class,
      opened: modal.methods.add_modal_open_class
    }
  )
}

export function confirmModal (ctx, props) {
  openModal(ctx, 'confirmation-modal', Confirm, props)
}
