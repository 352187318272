





















export default {
  props: {
    src: {
      type: String,
      default: null
    },
    img: {
      default: null
    },
    id_md5: {
      default: null
    },
    caption: {
      type: String
    },
    error: {
      type: String
    },
    isCanSelect: {
      type: Boolean,
      default: false
    },
    isCanDelete: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'image'
    },
    size: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    resolution: {
      type: String,
      default: ''
    },
    customWidth: {
      type: [String, Number],
      default: '120px'
    },
    customHeight: {
      type: [String, Number],
      default: '80px'
    }
  },
  computed: {
    width () {
      return typeof this.customWidth === 'string' ? this.customWidth : `${this.customWidth}px`
    },
    height () {
      return typeof this.customHeight === 'string' ? this.customHeight : `${this.customHeight}px`
    }
  }
}
