















import Vue from 'vue'

export default Vue.extend({
  name: 'CarouselFrame',
  props: {
    frame: {
      type: Object,
      required: true
    },
    media: {
      type: Array,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    totalIncluded: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      required: true
    }
  },
  computed: {
    baseUrl () {
      return process.env.VUE_APP_BASE_URL
    },
    image () {
      if (!this.included) {
        return require('@/assets/ads-manager/big-plus.svg')
      }
      const link = this.frame?.image?.hash ? this.media
        .find((media) => media.attributes.md5_hash === this.frame.image.hash)
        ?.attributes?.link : ''
      return link
        ? this.baseUrl + link
        : require('@/assets/ads-manager/fb-preview.svg')
    },
    included (): boolean {
      return this.frame?.state === 'included'
    },
    active (): boolean {
      return this.frame?.active
    }
  }
})
