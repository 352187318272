




























































import Vue from 'vue'
import PrimaryText from '@/components/ADSManager/NewCampaign/AdDesign/PrimaryText.vue'
import TextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import CarouselForm from '@/components/ADSManager/NewCampaign/Carousel/CarouselForm.vue'
import CarouselFrame from '@/components/ADSManager/NewCampaign/Carousel/CarouselFrame.vue'

export default Vue.extend({
  name: 'Carousel',
  components: {
    CarouselFrame,
    CarouselForm,
    PrimaryText,
    TextArea
  },
  data: () => ({
    scroll: 0
  }),
  props: {
    ABTesting: {
      type: Boolean,
      default: true
    },
    frames: {
      type: Array,
      required: true
    },
    callToActions: {
      type: Array,
      required: true
    },
    media: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    multi_share_optimized: {
      type: Boolean,
      required: true
    },
    primaryText: {
      default: ''
    }
  },
  computed: {
    ad_text_error () {
      const error = this.errors.find(err => err?.source?.parameter === 'ad_text')
      if (error) {
        return error.detail[0]
      }
      return !this.primaryText ? 'Primary Text field is required' : ''
    },
    deletable () {
      return this.totalIncluded > 2
    },
    totalIncluded () {
      return this.frames.filter(frame => frame.state === 'included').length || 0
    },
    activeFrame () {
      const _return = this.frames.find(frame => frame.active) || null

      if (_return) {
        this.chooseScrollHandler(_return.id)
      }

      return this.frames.find(frame => frame.active) || null
    },
    left (): string {
      // `max(0, min(calc(100% - 106px), calc(34px + 140px * ${activeFrame.id} - ${scroll}px))`
      const left = 34 + 140 * this.activeFrame.id - (this.scroll || 0)

      if (left < 0) {
        return '0'
      }

      return `${left}px`
    }
  },
  methods: {
    scrollHandler (event): void {
      this.scroll = event?.target?.scrollLeft || 0
    },
    chooseScrollHandler (event): void {
      const scrollable: HTMLElement = this.$refs.carousel__frames
      if (scrollable) {
        if (scrollable.clientWidth >= scrollable.scrollWidth) {
          return null
        } else {
          scrollable.scroll({ left: 65 + 140 * event - scrollable.clientWidth / 2, top: 0, behavior: 'smooth' })
        }
      }
    }
  }
})
