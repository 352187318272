export default {
  methods: {
    remove_modal_open_class () {
      ('body').removeClass('modal-open')
    },
    add_modal_open_class () {
      ('body').addClass('modal-open')
    }
  }
}
