

















































































import Vue from 'vue'

import Modal from '@/components/Modal.vue'
import modal from '../../Modals/mixins_modal'
import { confirmModal } from '../../Modals'
import Media from './Media.vue'
import Upload from './Upload.vue'
import Confirm from '../../../components/Modals/Confirm.vue'
import ProgressBar from '@/components/ADSManager/NewCampaign/ProgressBar.vue'

import { mapActions, mapState, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'MediaModal',
  components: { Media, Upload, Modal, ProgressBar, Confirm },
  props: {
    tabActive: {
      type: Number,
      default: 1
    },
    selectedMedia: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  mixins: [modal],
  data () {
    return {
      tabs: ['Library', 'Upload files'],
      selected: [],
      progressShow: false,
      progress: 0,
      uploadingStatus: false,
      media: []
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignData']),
    count () {
      return this.selected.length
    }
  },
  methods: {
    ...mapActions('adsManager/adDesign', ['save_media', 'delete_media']),
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp']),
    close () {
      this.$modal.hide('media-modal')
      this.$emit('close')
    },
    changeMedia () {
      this.progress = 0
      this.uploadingStatus = false
      this.progressShow = false
    },
    saveMedia () {
      this.progressShow = true
      setTimeout(() => {
        for (let i = 0; i <= 100; i++) {
          this.progress = i
        }
      }, 200)
      const promise = this.save_media()
      this.$emit('uploadMedia', promise)
      promise
        .then(() => {
          for (let i = 71; i <= 100; i++) {
            this.progress = i
            setTimeout(() => {
              this.progressShow = false
              this.progress = 0
              this.$modal.hide('media-modal')
              this.setStateFieldProp({ field: 'adDesignData', prop: 'mediaForUploading', value: [] })
            }, 1000)
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.uploadingStatus = true
          }, 1000)
        })
    },
    switchTab (id) {
      this.$emit('switchTab', id)
    },
    selectMedia (mid) {
      this.selected.includes(mid)
        ? (this.selected = this.selected.filter(id => id !== mid))
        : this.multiple
          ? this.selected.push(mid)
          : (this.selected = [mid])
    },
    openConfirm () {
      confirmModal(this, { onSuccess: this.removeImages })
    },
    deleteMedia () {
      this.delete_media({ image_hashes: this.selected.map(v => v.attributes.md5_hash) })
        .then(() => {
          this.selected = []
        })
    },
    removeImages () {
      this.selected = []
      this.deleteMedia(this.selected)
      this.$modal.hide('confirmation-modal')
    },
    checkMedia () {
      this.selected = this.selected.filter(s => this.adDesignData.images.includes(s.attributes.md5_hash))
    }
  }
})
