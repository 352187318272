export const messages = {
  en: {
    error: {
      required: '{field} is required',
      minLength: '{field} must be more than {length} characters',
      email: '{field} must be valid',
      alpha: '{field} must be only alphabet characters',
      sameAs: '{field} must be the same as {name}',
      accepted: '{field} must be accepted',
      empty: 'This field must not be empty'
    }
  }
}
