
































import Vue from 'vue'

export default Vue.extend({
  name: 'TabSwitcher',
  data () {
    return {}
  },
  props: {
    tabMenu: {
      type: Array
    },
    activeTab: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'nav-tabs',
      validator: function (value) {
        return ['nav-tabs', 'nav-FAQTabs-indicator', 'button-group', 'badge-group'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    switchTab (index: number, name: string) {
      if (!this.disabled) {
        this.$emit('switchTab', { index: index, name: name, activeTab: this.tabMenu[index] })
      }
    }
  }
})
