





























import Vue from 'vue'

import VSelect from '../../../../components/BaseComponents/VSelect/VSelect.vue'
import Input from '../../../../components/BaseInputVuelidate.vue'
import { required } from 'vuelidate/lib/validators'
import { messages } from '../../../../i18n/messages'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'AdsNameTemplate',
  components: { VSelect, Input },
  props: {
    name: {
      required: true
    },
    mode: {
      default: 'publication',
      validator: function (value) {
        return ['publication', 'editing'].indexOf(value) !== -1
      }
    }
  },
  validations: {
    adNameTemplate: {
      required
    }
  },
  i18n: {
    messages: messages
  },
  methods: {
    replaceSpace (arg: string) {
      return arg.toLowerCase().split(' ').join('_')
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignServerData']),
    adNameTemplate: {
      get () {
        return this.name
      },
      set (v) {
        if (this.name !== v) {
          this.$emit('change-name', v)
        }
      }
    }
  }
})
