























































































import Vue from 'vue'
import ImageItem from '@/components/ADSManager/NewCampaign/ImageItem.vue'
import ImageList from './../ImageList.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'

export default Vue.extend({
  name: 'Media',
  components: { ImageItem, ImageList, BaseAlert },
  props: {
    type_of_media: {
      type: String,
      required: true
    },
    images: {
      required: true
    },
    media: {
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    getMediaImage (image) {
      return this.media.find(v => v.attributes.md5_hash === image) || null
    },
    getSrc (image) {
      const link = this.getMediaImage(image)?.attributes?.link
      return link ? this.imageBaseUrl + link : ''
    },
    getName (image) {
      return this.getMediaImage(image)?.attributes?.real_name || ''
    },
    getResolution (image) {
      const m_image = this.getMediaImage(image)

      return m_image
        ? `${m_image.attributes.width} x ${m_image.attributes.height}`
        : ''
    }
  }
})
