import { render, staticRenderFns } from "./InstagramAccount.vue?vue&type=template&id=49d382cb&scoped=true&"
import script from "./InstagramAccount.vue?vue&type=script&lang=ts&"
export * from "./InstagramAccount.vue?vue&type=script&lang=ts&"
import style0 from "./InstagramAccount.vue?vue&type=style&index=0&id=49d382cb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d382cb",
  null
  
)

export default component.exports