



















import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import VSelect from '../../../../components/BaseComponents/VSelect/VSelect.vue'
export default Vue.extend({
  name: 'FacebookPage',
  components: { VSelect },
  data () {
    return {
      selected: {}
    }
  },
  props: {
    serverData: {
      required: true
    },
    editable: {
      default: true
    }
  },
  watch: {
    selected (v) {
      if (this.editable) {
        this.setStateFieldProp({ field: 'adDesignData', prop: 'instagram_id', value: v.id })
      }
    },
    accounts (v) {
      this.selected = v[0]
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignData']),
    accounts () {
      return this.serverData.instagram_accounts
    }
  },
  methods: {
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp']),
    ...mapActions('adsManager/adDesign', ['get_instant_forms'])
  },
  mounted () {
    this.selected = this.serverData.instagram_accounts[0]
  }
})
