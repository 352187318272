
































































































import Vue from 'vue'
import TabSwitcher from '@/components/TabSwitcher.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import BaseTextarea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import { url } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'

export default Vue.extend({
  name: 'CarouselForm',
  components: { BaseAlert, BaseInput, BaseTextarea, TabSwitcher, VSelect },
  props: {
    frame: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    callToActions: {
      type: Array,
      required: true
    }
  },
  validations: {
    url: {
      url
    }
  },
  i18n: {
    messages: messages
  },
  data: () => ({
    tabs: [{ title: 'Photo', name: 'photo' }]
  }),
  computed: {
    url () {
      return this.frame.url.url
    },
    errorMessages () {
      return {
        headline: this.errors.find((error) => error.field === 'headline')?.detail ||
          this.errors.find((error) => error?.source?.parameter.includes('headline'))?.detail[0] ||
          (!this.frame.headline.headline ? 'Headline is required' : ''),
        ad_text: this.errors.find((error) => error.field === 'ad_text')?.detail ||
          this.errors.find((error) => error?.source?.parameter.includes('ad_text'))?.detail[0] ||
          (!this.frame.ad_text.ad_text ? 'Description is required' : ''),
        image: this.errors.find((error) => error.field === 'image')?.detail ||
          this.errors.find((error) => error?.source?.parameter.includes('image'))?.detail[0] ||
          '',
        url: this.errors.find((error) => error.field === 'url')?.detail ||
          this.errors.find((error) => error?.source?.parameter.includes('url'))?.detail[0] ||
          (!this.frame.url.url ? 'Target link is required' : '')
      }
    }
  }
})
