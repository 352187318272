













import Vue from 'vue'
export default Vue.extend({
  name: 'ProgressBar',

  props: {
    value: {
      default: 0
      //   min: 0,
      //   max: 100
    },
    error: {
      type: Boolean,
      default: true
    },
    isWithBadge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerWidth () {
      return 'width:' + this.value + '%'
    }
  }
})
